var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"item-key":"mProvId","hide-default-footer":"","items":_vm.comprobantesItems,"loading":_vm.loadingTable,"loading-text":"Cargando datos"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pb-0"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"10","align":"start"}},[_c('v-card-title',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.title))])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.goToVerComprobante(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Ver comprobante")])]),(_vm.canDelete)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.goToDeleteComprobante(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Eliminar comprobante")])]):_vm._e()]}}],null,true)}),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","align":"end"}},[_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1),(_vm.openModalDelete)?_c('v-dialog',{attrs:{"max-width":"65%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.openModalDelete = false}},model:{value:(_vm.openModalDelete),callback:function ($$v) {_vm.openModalDelete=$$v},expression:"openModalDelete"}},[_c('DetalleComprobanteProv',{attrs:{"isDelete":true,"paramsToDelete":_vm.paramsToDelete},on:{"closeModalDelete":function($event){_vm.openModalDelete = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }