<template>
  <div>
    <v-card class="px-4">
      <v-row>
        <v-col cols="12">
          <v-card class="black-color">
            <v-card-title class="h6 primary--text"
              >Datos del encabezado de la OP</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-card-title class="h6">Proveedor</v-card-title>
                    <v-card-text>
                      <v-row>
                        <!-- Proveedor -->
                        <v-col cols="4" sm="5" md="5" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Proveedor"
                            v-model="proveedor"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- CUIT -->
                        <v-col cols="4" sm="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="CUIT"
                            v-model="cuit"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Tipo IVA -->
                        <v-col cols="4" sm="4" md="4" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Tipo IVA"
                            v-model="tipoIva"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Dirección -->
                        <v-col cols="4" sm="5" md="5" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Dirección"
                            v-model="direccion"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Modalidad de pago -->
                        <v-col cols="4" sm="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Modalidad de pago"
                            v-model="modalidadPago"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Destino de pago -->
                        <v-col cols="4" sm="4" md="4" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Destino de pago"
                            v-model="destinoPago"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-card-title class="h6">Comprobante</v-card-title>
                    <v-card-text>
                      <v-row>
                        <!-- Tipo de comprobante -->
                        <v-col cols="4" sm="5" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Tipo de comprobante"
                            v-model="tipoComprobante"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Comprobante -->
                        <v-col cols="4" sm="4" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Comprobante"
                            v-model="comprobante"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Fecha -->
                        <v-col cols="3" sm="3" md="2" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Fecha"
                            v-model="fecha"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- fecha contable -->
                        <v-col
                          cols="3"
                          sm="3"
                          md="2"
                          class="py-0"
                          v-if="paramsToDeleteOp.isCtaCte"
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Fecha contable"
                            v-model="fechaContable"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <!-- Moneda del comprobante -->
                        <v-col
                          cols="12"
                          md="3"
                          class="py-0"
                          v-if="
                            this.paramGralMultimoneda &&
                            this.paramsToDeleteOp.isCtaCte
                          "
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Moneda del comprobante"
                            v-model="monedaComprobante"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Fecha de cotizacion -->
                        <v-col
                          cols="2"
                          md="2"
                          class="py-0"
                          v-if="
                            this.paramGralMultimoneda &&
                            paramsToDeleteOp.isCtaCte
                          "
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Fecha cotización"
                            v-model="fechaCotizacion"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Moneda de consulta -->
                        <v-col
                          cols="12"
                          md="3"
                          class="py-0"
                          v-if="
                            this.paramGralMultimoneda &&
                            paramsToDeleteOp.isCtaCte
                          "
                        >
                          <v-autocomplete
                            v-model="monedaConsultaSelected"
                            :items="monedasConsultaItems"
                            item-text="value"
                            item-value="id"
                            return-object
                            label="Moneda de consulta"
                            @change="setComprobanteByMoneda()"
                            outlined
                            dense
                          >
                          </v-autocomplete>
                          <p
                            class="invalidConfigText mt-n5"
                            v-if="this.haveCotiza"
                          >
                            Sin cotización
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-card-title class="h6"
                      >Datos del comprobante</v-card-title
                    >
                    <v-card-text>
                      <v-row>
                        <!-- Neto -->
                        <v-col cols="3" sm="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            prepend-inner-icon="mdi-currency-usd"
                            class="right-input"
                            readonly
                            label="Neto"
                            v-model="importeNeto"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- IVA -->
                        <v-col cols="3" sm="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            prepend-inner-icon="mdi-currency-usd"
                            label="IVA"
                            v-model="iva"
                            class="right-input"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Percepción IVA -->
                        <v-col cols="3" sm="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            prepend-inner-icon="mdi-currency-usd"
                            label="Percepción IVA"
                            class="right-input"
                            v-model="percepcionIva"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Percepción IIBB -->
                        <v-col cols="3" sm="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            prepend-inner-icon="mdi-currency-usd"
                            readonly
                            class="right-input"
                            label="Percepción IIBB"
                            v-model="percepcionIIBB"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Impuestos internos -->
                        <v-col cols="4" sm="4" md="4" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            prepend-inner-icon="mdi-currency-usd"
                            filled
                            readonly
                            class="right-input"
                            label="Impuestos internos"
                            v-model="impuestosInternos"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Otros conceptos -->
                        <v-col cols="4" sm="4" md="4" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            prepend-inner-icon="mdi-currency-usd"
                            dense
                            filled
                            readonly
                            class="right-input"
                            label="Otros conceptos"
                            v-model="otrosConceptos"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                        <!-- Total -->
                        <v-col cols="4" sm="4" md="4" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            prepend-inner-icon="mdi-currency-usd"
                            class="right-input"
                            label="Total"
                            v-model="importeTotal"
                            autocomplete="off"
                            
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card >
            <v-card-title class="h6"
              >Listado de comprobantes cancelados</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headersComprobantesCancelados"
                    :items="comprobantesCanceladosList"
                    class="elevation-1"
                    hide-default-footer
                    :items-per-page="999"
                    dense
                  >
                  </v-data-table
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card >
            <v-card-title class="h6">Listado de retenciones</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headersRetenciones"
                    :items="retencionesList"
                    :items-per-page="999"
                    class="elevation-1"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:[`item.tipoValorNombre`]="{ item }">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on"
                            @click="printRetencion(item.mpValId)"
                            class="blue-text"
                          >
                            {{ item.tipoValorNombre }}
                          </div>
                        </template>
                        <span>Imprimir</span>
                      </v-tooltip>
                    </template>
                  </v-data-table></v-col
                >
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card >
            <v-card-title class="h6">Listado de valores</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headersValores"
                    :items="valoresList"
                    class="elevation-1"
                    :items-per-page="999"
                    hide-default-footer
                    dense
                  >
                  </v-data-table
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions class="p-0 pb-3 mt-3">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeDeleteModal()"> Cancelar </v-btn>
        <v-btn color="primary" @click="validateDeleteComp()"> Eliminar </v-btn>
      </v-card-actions>
    </v-card>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :textDelete="textDelete"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="secondConfirmDelete()"
    />
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="secondConfirmDelete()"
      @update:openConfirm="cancelarDialog"
      :submitBtn="'ELIMINAR'"
      :cancelBtn="'CANCELAR'"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "VerOrdenDePagoEliminar",
  async created() {
    this.setParamsMultimoneda();
    this.setDetalleOrdenPago();
  },
  components: {
    DeleteDialog,
    ConfirmDialog,
  },
  props: {
    paramsToDeleteOp: { type: Object, required: false, default: null },
  },
  data() {
    return {
      showDeleteModal: false,
      loadingBtnDelete: false,
      titleDelete: enums.titles.ELIMINAR_COMPROBANTE,
      proveedor: null,
      cuit: null,
      tipoIva: null,
      direccion: null,
      modalidadPago: null,
      destinoPago: null,
      tipoComprobante: null,
      textConfirmDialog: "",
      openConfirmDialog: false,
      comprobante: null,
      fecha: null,
      textDelete: null,
      fechaContable: null,
      importeNeto: null,
      iva: null,
      percepcionIva: null,
      percepcionIIBB: null,
      impuestosInternos: null,
      otrosConceptos: null,
      importeTotal: null,
      paramGralMultimoneda: true,
      haveCotiza: false,
      monedaComprobante: null,
      monedaConsultaSelected: null,
      fechaCotizacion: null,
      monedasConsultaItems: [],
      comprobantesCanceladosList: [],
      headersComprobantesCancelados: [
        {
          text: "Fecha",
          value: "fecha",
          sortable: false,
          align: "start",
        },
        {
          text: "Tipo comprobante",
          value: "tipoCompNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Comprobante",
          value: "compNro",
          sortable: false,
          align: "start",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado",
          value: "importeCancelado",
          sortable: false,
          align: "end",
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado O.P.",
          value: "canceladoOP",
          sortable: false,
          align: "end",
        },
      ],
      retencionesList: [],
      headersRetenciones: [
        {
          text: "Retención",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
      valoresList: [],
      headersValores: [
        {
          text: "Valor",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Fecha",
          value: "fechaValor",
          sortable: false,
          align: "start",
        },
        {
          text: "Banco",
          value: "bancoNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getDetalleOrdenPago: "proveedores/getDetalleOrdenPago",
      getComprobanteProveedorById: "proveedores/getComprobanteProveedorById",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      imprimirArchivoOrdenesPagoPdf:
        "proveedores/imprimirArchivoOrdenesPagoPdf",
      deleteComprobantesProveedor: "proveedores/deleteComprobantesProveedor",
      validateDeleteComprobante: "proveedores/validateDeleteComprobante",
      setAlert: "user/setAlert",
    }),
    async setParamsMultimoneda() {
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      const dataMonedas = await this.getMonedasTaxonomy("habListado");
      this.monedasConsultaItems = dataMonedas;
    },
    async setDetalleOrdenPago() {
      const idOp = this.paramsToDeleteOp.movProvId;
      this.haveCotiza = false;
      if (this.paramsToDeleteOp.isCtaCte) {
        const data = {
          mProvId: this.paramsToDeleteOp.movProvId,
          monedaId: this.paramsToDeleteOp.monedaToDetalle?.id,
          fechaCotiza: this.paramsToDeleteOp.fechaCotizaToDetalle,
        };
        this.fechaCotizacion = this.formatDate(
          this.paramsToDeleteOp.fechaCotizaToDetalle
        );
        const response = await this.getComprobanteProveedorById(data);
        if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
        this.idEntFac = response.cabecera.entFacId;
        this.proveedor = response.cabecera.proveedorNombre;
        this.cuit = response.cabecera.cuit;
        this.tipoIva = response.cabecera.tipoIvaNombre;
        this.direccion = response.cabecera.direccion;
        this.modalidadPago = response.cabecera.modalidadPago;
        this.destinoPago = response.cabecera.detinoPago;
        this.tipoComprobante = response.cabecera.tcompNombre;
        this.comprobante = response.cabecera.compNro;
        this.fecha = response.cabecera.fecha;
        this.fechaContable = response.cabecera.fechaContable;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        this.monedaComprobante = response.cabecera.monedaNombre;
        this.monedaConsultaSelected = this.paramsToDeleteOp.monedaToDetalle;
        //datos listas
        this.retencionesList = response.retenciones;
        this.valoresList = response.valores;
        this.comprobantesCanceladosList = response.comprobantesCancelados;
      } else {
        const response = await this.getDetalleOrdenPago(idOp);
        this.idEntFac = response.cabecera.entFacId;
        this.proveedor = response.cabecera.proveedorNombre;
        this.cuit = response.cabecera.cuit;
        this.tipoIva = response.cabecera.tipoIvaNombre;
        this.direccion = response.cabecera.direccion;
        this.modalidadPago = response.cabecera.modalidadPago;
        this.destinoPago = response.cabecera.detinoPago;
        this.tipoComprobante = response.cabecera.tcompNombre;
        this.comprobante = response.cabecera.compNro;
        this.fecha = response.cabecera.fecha;
        this.fechaContable = response.cabecera.fechaContable;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        //datos listas
        this.retencionesList = response.retenciones;
        this.valoresList = response.valores;
        this.comprobantesCanceladosList = response.comprobantesCancelados;
      }
      this.movProvIdOp = idOp;
      this.mail = this.paramsToDeleteOp.email;
    },
    async validateDeleteComp() {
      try {
        const response = await this.validateDeleteComprobante(
          this.paramsToDeleteOp.movProvId
        );
        if (response.tieneAdvertencia) {
          this.loadingBtnDelete = false;
          this.showDeleteModal = false;
          this.openConfirmDialog = true;
          this.textConfirmDialog = `${response.mensajeValidacion}¿Desea eliminar de todas formas?`;
        } else {
          if (response.mensajeValidacion == null) {
            //abrir dialog confirmacion
            this.showDeleteModal = true;
          } else {
            this.setAlert({
              type: "warning",
              message: response.mensajeValidacion,
            });
            this.loadingBtnDelete = false;
            this.showDeleteModal = false;
          }
        }
      } catch (err) {}
    },
    async secondConfirmDelete() {
      try {
        this.loadingBtnDelete = true;
        const response = await this.deleteComprobantesProveedor(
          this.paramsToDeleteOp.movProvId
        );
        this.setAlert({
          type: response === "Comprobante Eliminado" ? "success" : "error",
          message:
            response === "Comprobante Eliminado"
              ? "Comprobante eliminado con éxito."
              : response,
        });
      } catch (err) {}
      this.loadingBtnDelete = false;
      this.showDeleteModal = false;
      this.closeDeleteModal();
    },
    cancelarDialog() {
      this.textConfirmDialog = "";
    },
    closeDeleteModal() {
      this.$emit("closeModalDelete");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>


<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(0, 0, 0);
}
.right-input >>> input {
  text-align: right;
}
.blue-text {
  color: blue;
  text-decoration: underline blue;
}
.btnDisabled {
  color: #100f0f99;
}
.invalidConfigText {
  color: red;
}
</style>
