<template>
  <div>
    <v-container v-if="!isDelete">
      <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-row class="mb-1">
                      <v-col cols="2" class="mt-n4 mb-n10">
                        <v-card-title class="h6">Proveedor</v-card-title>
                      </v-col>
                      <v-col cols="5" v-if="this.haveNumeroExpediente">
                        <p>
                          El comprobante pertenece al expediente Nº
                          {{ this.numeroExpediente }}
                        </p>
                      </v-col>
                      <v-col cols="6" v-if="this.vieneDeLiqVendedor">
                        <p>
                          Comprobante generado desde un proceso de liquidación
                          de comisión a vendedor
                        </p>
                      </v-col>
                      <v-col class="mt-n2 mb-n10 mr-4">
                        <v-btn
                          color="primary"
                          small
                          @click="openModal()"
                          class="to-right"
                        >
                          Datos de liquidación/SSS
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-card-text>
                      <v-row
                        :class="
                          this.haveNumeroExpediente || this.vieneDeLiqVendedor
                            ? 'mb-n7 mt-n7'
                            : 'mb-n7'
                        "
                      >
                        <!-- Proveedor -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Proveedor"
                            v-model="proveedor"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- CUIT -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="CUIT"
                            v-model="cuit"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Tipo IVA -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Condición de IVA"
                            v-model="tipoIva"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Tipo de operacion -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Tipo de operación"
                            v-model="tipoOperacion"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-card>
                    <v-card-title class="h6 mt-n2">Comprobante</v-card-title>
                    <v-card-text>
                      <v-row class="mb-n2 mt-n3">
                        <!-- Entidad facturante -->
                        <v-col cols="5" md="6" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Entidad facturante"
                            v-model="entidadFacturante"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Tipo de comprobante -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Tipo de comprobante"
                            v-model="tipoComprobante"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Comprobante numero -->
                        <v-col cols="3" md="3" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Comprobante número"
                            v-model="comprobanteNum"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mb-n2">
                        <!-- Fecha -->
                        <v-col
                          cols="2"
                          :md="this.paramGralMultimoneda ? 2 : 3"
                          class="py-0"
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Fecha"
                            v-model="fecha"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Fecha vencimiento -->
                        <v-col
                          cols="2"
                          :md="this.paramGralMultimoneda ? 2 : 3"
                          class="py-0"
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Fecha de vencimiento"
                            v-model="fechaVencimiento"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Fecha contable -->
                        <v-col
                          cols="2"
                          :md="this.paramGralMultimoneda ? 2 : 3"
                          class="py-0"
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Fecha contable"
                            v-model="fechaContable"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Periodo -->
                        <v-col cols="4" md="2" class="py-0">
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Período"
                            v-model="periodo"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="mb-n7">
                        <!-- Moneda del comprobante -->
                        <v-col
                          cols="12"
                          md="2"
                          class="py-0"
                          v-if="this.paramGralMultimoneda"
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Moneda del comp."
                            v-model="monedaComprobante"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Cotizacion del comprobante -->
                        <v-col
                          cols="12"
                          md="2"
                          class="py-0"
                          v-if="
                            this.paramGralMultimoneda &&
                            this.cotizacionComprobante != null
                          "
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Cotización del comp."
                            v-model="cotizacionComprobante"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                        </v-col>
                        <!-- Moneda de consulta -->
                        <v-col
                          cols="12"
                          md="2"
                          class="py-0"
                          v-if="this.paramGralMultimoneda"
                        >
                          <v-autocomplete
                            v-model="monedaConsultaSelected"
                            :items="monedasConsultaItems"
                            item-text="value"
                            item-value="id"
                            return-object
                            label="Moneda de consulta"
                            @change="setComprobanteByMoneda()"
                            outlined
                            dense
                          >
                          </v-autocomplete>
                          <p
                            class="invalidConfigText mt-n5"
                            v-if="this.haveCotiza && !this.isMonedaDefecto"
                          >
                            Sin cotización al {{ this.fechaSinCotiza }}
                          </p>
                        </v-col>
                        <!-- Fecha de cotizacion -->
                        <v-col
                          cols="2"
                          :md="this.paramGralMultimoneda ? 2 : 3"
                          v-if="
                            this.paramGralMultimoneda &&
                            this.disableFechaCotiza &&
                            !this.haveCotiza
                          "
                          class="py-0"
                        >
                          <v-text-field
                            type="text"
                            outlined
                            dense
                            filled
                            readonly
                            label="Cotización"
                            v-model="cotizacionAlaFecha"
                            autocomplete="off"
                            disabled
                          ></v-text-field>
                          <p class="mt-n6">
                            Cotiza. al {{ this.fechaCotizacion }}
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <!-- tabla de detalles alicuotas IVA -->
                    <v-data-table
                      :headers="headersAlicuotasIVA"
                      :items="itemsAlicuotasIVA"
                      height="100%"
                      fixed-header
                      item-key="mProvDetaId"
                      class="elevation-1"
                      :loading="isLoadingTable"
                      hide-default-footer
                    >
                      <template v-slot:[`item.neto`]="{ item }">
                        <span>{{ helpers.floatToMoneyString(item.neto) }}</span>
                      </template>
                      <template v-slot:[`item.iva`]="{ item }">
                        <span>{{ helpers.floatToMoneyString(item.iva) }}</span>
                      </template>
                      <template v-slot:[`item.total`]="{ item }">
                        <span>{{
                          helpers.floatToMoneyString(item.total)
                        }}</span>
                      </template>
                    </v-data-table>
                    <v-row class="mb-n2 mt-3">
                      <!-- Neto -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          prepend-inner-icon="mdi-currency-usd"
                          class="right-input"
                          readonly
                          label="Neto"
                          v-model="importeNeto"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- IVA -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          label="IVA"
                          v-model="iva"
                          class="right-input"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Percepción IVA -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          label="Percepción IVA"
                          class="right-input"
                          v-model="percepcionIva"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Percepción IIBB -->
                      <v-col cols="3" sm="3" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          prepend-inner-icon="mdi-currency-usd"
                          readonly
                          class="right-input"
                          label="Percepción IIBB"
                          v-model="percepcionIIBB"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mb-n7">
                      <!-- Impuestos internos -->
                      <v-col cols="4" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          prepend-inner-icon="mdi-currency-usd"
                          filled
                          readonly
                          class="right-input"
                          label="Impuestos internos"
                          v-model="impuestosInternos"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Otros conceptos -->
                      <v-col cols="4" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          prepend-inner-icon="mdi-currency-usd"
                          dense
                          filled
                          readonly
                          class="right-input"
                          label="Otros conceptos"
                          v-model="otrosConceptos"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                      <!-- Total -->
                      <v-col cols="4" md="3" class="py-0">
                        <v-text-field
                          type="text"
                          outlined
                          dense
                          filled
                          readonly
                          prepend-inner-icon="mdi-currency-usd"
                          class="right-input"
                          label="Total"
                          v-model="importeTotal"
                          autocomplete="off"
                          disabled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="12"
                        v-if="!this.llevaImputYCc"
                        class="py-0 mt-2 mb-n2 ml-1"
                      >
                        <p class="invalidConfigText">
                          El tipo de comprobante no requiere imputación contable
                        </p>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
            <v-col cols="12" v-if="this.haveComprobCancel">
              <v-card>
                <v-card-title class="h6">
                  Listado de comprobantes cancelados
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-data-table
                        :headers="headersComprobantesCancelados"
                        :items="comprobantesCanceladosList"
                        class="elevation-1"
                        hide-default-footer
                        :items-per-page="999"
                        dense
                      >
                      </v-data-table
                    ></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="this.haveListRetenciones">
              <v-card>
                <v-card-title class="h6"> Listado de retenciones </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-data-table
                        :headers="headersRetenciones"
                        :items="retencionesList"
                        :items-per-page="999"
                        class="elevation-1"
                        hide-default-footer
                        dense
                      >
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="this.haveListValores">
              <v-card>
                <v-card-title class="h6"> Listado de valores </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="pt-0">
                      <v-data-table
                        :headers="headersValores"
                        :items="valoresList"
                        class="elevation-1"
                        :items-per-page="999"
                        hide-default-footer
                        dense
                      >
                      </v-data-table
                    ></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" class="p-0 pt-1 pl-8 pr-8">
              <v-row v-if="this.llevaImputYCc">
                <v-col
                  cols="12"
                  md="12"
                  v-if="this.disableTextImputCble"
                  class="py-0 ml-n3 mt-n4 mb-n4"
                >
                  <p>
                    Las imputaciones contables y centros de costos están
                    expresadas en la moneda por defecto del sistema
                  </p>
                </v-col>
                <!-- IMPUTACION CONTABLE -->
                <v-col cols="6" md="6" class="p-0">
                  <v-data-table
                    :headers="imputacionContableHeaders"
                    :items="imputacionContableItems"
                    fixed-header
                    class="elevation-1"
                    hide-default-footer
                    dense
                    item-key="id"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="6" md="6" class="pb-0 pt-2">
                          <span
                            class="primary--text py-0 pl-2 font-weight-medium"
                            >Imputación contable</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                      <span>{{
                        helpers.floatToMoneyString(item.importe)
                      }}</span>
                    </template>
                    <template v-slot:footer>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span class="font-weight-medium text-right">
                            Total imputación contable:
                            {{
                              helpers.floatToMoneyString(
                                totalImputacionContable
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
                <!-- CENTRO DE COSTOS -->
                <v-col cols="6" md="6" class="p-0">
                  <v-data-table
                    :headers="centroCostosHeaders"
                    :items="centroCostosItems"
                    height="100%"
                    fixed-header
                    class="elevation-1"
                    hide-default-footer
                    dense
                    item-key="id"
                  >
                    <template v-slot:top>
                      <v-row>
                        <v-col cols="6" md="6" class="pt-2 pb-0">
                          <span
                            class="primary--text font-weight-medium py-0 pl-2"
                            >Centros de costos</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                    <template v-slot:[`item.importe`]="{ item }">
                      <span>{{
                        helpers.floatToMoneyString(item.importe)
                      }}</span>
                    </template>
                    <template v-slot:footer>
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                          class="pt-1 pb-2 pl-0 pr-6 text-right"
                        >
                          <span class="font-weight-medium text-right">
                            Total centros de costos:
                            {{
                              helpers.floatToMoneyString(totalCentrosDeCostos)
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-col>
            <!-- datos del CAE -->
            <v-row class="ml-1 mb-n5">
              <v-col cols="4" class="d-flex" v-if="!this.haveNumeroCAE">
                <p class="label">Número de CAE/CAI:</p>
                <strong class="ml-2 mb-0">{{ numeroCAE }}</strong>
              </v-col>
              <v-col cols="4" class="d-flex" v-if="!this.haveNumeroCAE">
                <p class="label">Fecha de vencimiento CAE/CAI:</p>
                <strong class="ml-2 mb-0">{{ fechaVencCAE }}</strong>
              </v-col>
              <v-col cols="3" class="d-flex">
                <p class="label">Tipo de emisión:</p>
                <strong class="ml-2 mb-0">{{ tipoEmision }}</strong>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex py-0 ml-5">
                <p class="label">Observación:</p>
                <strong class="ml-2 mb-0">{{ observacion }}</strong>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div v-else>
      <DetalleComprobanteProvEliminar
        @closeModalDelete="closeDeleteModal"
        :paramsToDelete="paramsToDelete"
      ></DetalleComprobanteProvEliminar>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import helpers from "@/utils/helpers.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import DetalleComprobanteProvEliminar from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProvEliminar.vue";

export default {
  name: "DetalleComprobanteProv",
  components: {
    PageHeader,
    GoBackBtn,
    DetalleComprobanteProvEliminar,
  },
  props: {
    isDelete: { type: Boolean, required: false, default: false },
    paramsToDelete: { type: Object, required: false, default: null },
  },
  created() {
    if (this.$store.state.filtersDatosToEstadoFactu == undefined || this.$store.state.filtersDatosToEstadoFactu == null) {
      if (!this.isDelete) this.setDetalleComprobante();
    } else {
      this.routeToGo = this.$store.state.filtersDatosToEstadoFactu.routeToGo;
      this.mProvId = this.$store.state.filtersDatosToEstadoFactu.mProvId;
      this.fechaCotizaToDetalle = this.$store.state.filtersDatosToEstadoFactu.fechaCotizaToDetalle;
      this.monedaPorDefecto = this.$store.state.filtersDatosToEstadoFactu.monedaDefecto;
      this.monedaDeProveedor = this.$store.state.filtersDatosToEstadoFactu.monedaProveedor;
      this.monedaToDetalle = this.$store.state.filtersDatosToEstadoFactu.monedaToDetalle;
      this.setDetalleComprobante();
    }
  },
  data() {
    return {
      routeToGo: this.$route.params.goBackName,
      helpers: helpers,
      mProvId: null,
      proveedor: null,
      cuit: null,
      tipoIva: null,
      tipoOperacion: null,
      tipoComprobante: null,
      comprobanteNum: null,
      entidadFacturante: null,
      periodo: null,
      fecha: null,
      fechaContable: null,
      fechaVencimiento: null,
      fechaVencCAE: null,
      numeroCAE: null,
      tipoEmision: null,
      importeNeto: null,
      iva: null,
      percepcionIva: null,
      numeroExpediente: 0,
      haveNumeroExpediente: false,
      vieneDeLiqVendedor: false,
      percepcionIIBB: null,
      impuestosInternos: null,
      otrosConceptos: null,
      importeTotal: null,
      observacion: null,
      monedaPorDefecto: null,
      monedaDeProveedor: null,
      monedaToDetalle: null,
      monedaComprobante: null,
      monedaConsultaSelected: null,
      cotizacionComprobante: null,
      fechaCotizatoDetalle: null,
      fechaCotizacion: null,
      fechaSinCotiza: null,
      cotizacionAlaFecha: null,
      monedasConsultaItems: [],
      totalCentrosDeCostos: 0,
      totalImputacionContable: 0,
      haveComprobCancel: false,
      haveListRetenciones: false,
      haveNumeroCAE: false,
      haveListValores: false,
      llevaImputYCc: false,
      isLoadingTable: false,
      isMonedaDefecto: false,
      paramGralMultimoneda: false,
      haveCotiza: false,
      disableFechaCotiza: false,
      disableTextImputCble: false,
      comprobantesCanceladosList: [],
      headersComprobantesCancelados: [
        {
          text: "Fecha",
          value: "fecha",
          sortable: false,
          align: "start",
        },
        {
          text: "Tipo comprobante",
          value: "tipoCompNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Comprobante",
          value: "compNro",
          sortable: false,
          align: "start",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado",
          value: "importeCancelado",
          sortable: false,
          align: "end",
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado O.P.",
          value: "canceladoOP",
          sortable: false,
          align: "end",
        },
      ],
      retencionesList: [],
      headersRetenciones: [
        {
          text: "Retención",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
      valoresList: [],
      headersValores: [
        {
          text: "Valor",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Fecha",
          value: "fechaValor",
          sortable: false,
          align: "start",
        },
        {
          text: "Banco",
          value: "bancoNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
      itemsAlicuotasIVA: [],
      headersAlicuotasIVA: [
        {
          text: "Concepto",
          sortable: false,
          value: "concepto",
        },
        {
          text: "Alícuota de IVA",
          sortable: false,
          value: "condicionIVA",
        },
        {
          text: "Porcentaje de IVA",
          sortable: false,
          value: "ivaPorc",
          align: "end",
        },
        {
          text: "Neto",
          sortable: false,
          value: "neto",
          align: "end",
        },
        {
          text: "IVA",
          sortable: false,
          value: "iva",
          align: "end",
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
          align: "end",
        },
      ],
      imputacionContableHeaders: [
        {
          text: "Cuenta",
          sortable: false,
          value: "cuenta",
          align: "start",
        },
        {
          text: "Descripción",
          sortable: false,
          value: "descripcion",
          align: "start",
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end",
        },
      ],
      centroCostosHeaders: [
        {
          text: "Nombre",
          sortable: false,
          value: "nombre",
          align: "start",
        },
        {
          text: "Importe",
          sortable: false,
          value: "importe",
          align: "end",
        },
      ],
      imputacionContableItems: [],
      centroCostosItems: [],
      filtrosGuardados: {},
    };
  },
  methods: {
    ...mapActions({
      getComprobanteProveedorById: "proveedores/getComprobanteProveedorById",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getFacturaDetalleCompletoProv: "proveedores/getFacturaDetalleCompletoProv",
    }),
    async setDetalleComprobante() {
      if ((this.$store.state.filtersDatosToEstadoFactu == undefined || this.$store.state.filtersDatosToEstadoFactu == null) ||
        (this.$route.params.movProvId != undefined)) {
        const responseDetalle = await this.getFacturaDetalleCompletoProv(this.$route.params.movProvId);
        if (responseDetalle.numeroExpediente > 0) {
          this.haveNumeroExpediente = true;
          this.numeroExpediente = responseDetalle.numeroExpediente;
        }
        this.vieneDeLiqVendedor = responseDetalle.vieneDeLiqVendedores;
        this.llevaImputYCc = responseDetalle.tipoCompLlevaImputCble;
        this.imputacionContableItems = responseDetalle.imputContablesList;
        this.centroCostosItems = responseDetalle.centrosCostosList;
        if(this.centroCostosItems != null){
          this.totalCentrosDeCostos = responseDetalle.centrosCostosList.reduce((sum, cc) => {
            return sum + cc.importe;},0);
        }
        if(this.imputacionContableItems != null){
          this.totalImputacionContable = responseDetalle.imputContablesList.reduce((sum, impCont) => {
            return sum + impCont.importe;}, 0);
        }
        const dataMonedas = await this.getMonedasTaxonomy("habListado");
        this.monedasConsultaItems = dataMonedas;
        const responseParametros = await this.getParamGralMultimoneda();
        this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
        this.haveCotiza = false;
        const data = {
          mProvId: this.$route.params.movProvId,
          //se agrego "?" para que no de error cuando viene de comprobantes proveedor sin moneda
          monedaId: this.$route.params.monedaToDetalle?.id,
          fechaCotiza: this.$route.params.fechaCotizaToDetalle,
        };
        if (this.$route.params.fechaCotizaToDetalle != null) {
          this.fechaCotizacion = this.formatDate(this.$route.params.fechaCotizaToDetalle);
          this.disableFechaCotiza = true;
          this.fechaSinCotiza = this.formatDate(this.$route.params.fechaCotizaToDetalle);
        }
        const response = await this.getComprobanteProveedorById(data);
        this.itemsAlicuotasIVA = response.alicuotas;
        if (response.comprobantesCancelados.length != 0) {
          this.haveComprobCancel = true;
          this.comprobantesCanceladosList = response.comprobantesCancelados;
        }
        if (response.retenciones.length != 0) {
          this.haveListRetenciones = true;
          this.retencionesList = response.retenciones;
        }
        if (response.valores.length != 0) {
          this.haveListValores = true;
          this.valoresList = response.valores;
        }
        if (response.cabecera.nroCAE == "") {
          this.haveNumeroCAE = true;
        }
        if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
        if (response.cabecera.tipoEmision == "I") this.tipoEmision = "Impreso";
        else if (response.cabecera.tipoEmision == "E")
          this.tipoEmision = "Electrónico";
        else this.tipoEmision = "";
        this.proveedor = response.cabecera.proveedorNombre;
        this.cuit = response.cabecera.cuit;
        this.tipoIva = response.cabecera.tipoIvaNombre;
        this.tipoOperacion = response.cabecera.tipoOperacion;
        this.tipoComprobante = response.cabecera.tcompNombre;
        this.comprobanteNum = response.cabecera.compNro;
        this.entidadFacturante = response.cabecera.entFacNombre;
        this.periodo = response.cabecera.periodo;
        this.fecha = response.cabecera.fecha;
        this.fechaContable = response.cabecera.fechaContable;
        this.fechaVencimiento = response.cabecera.fechaVencimiento;
        this.fechaVencCAE = response.cabecera.fechaVtoCAE;
        this.monedaComprobante = response.cabecera.monedaNombre;
        this.cotizacionComprobante = response.cabecera.cotizacionComp;
        this.numeroCAE = response.cabecera.nroCAE;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        this.observacion = response.cabecera.observacion;
        this.cotizacionAlaFecha = response.cabecera.haveCotiza;
        this.monedaConsultaSelected = this.$route.params.monedaToDetalle;
        if(this.$route.params.monedaDefecto.id == this.$route.params.monedaProveedor.id || 
        this.$route.params.monedaProveedor.id == this.monedaConsultaSelected.id) this.disableFechaCotiza = false;
        if (this.$route.params.monedaDefecto.id != this.$route.params.monedaProveedor.id) this.disableTextImputCble = true;
        this.filtrosGuardados = {
          routeToGo: this.$route.params.goBackName,
          mProvId: this.$route.params.movProvId,
          fechaCotizaToDetalle: this.$route.params.fechaCotizaToDetalle,
          monedaToDetalle: this.$route.params.monedaToDetalle,
          monedaDefecto: this.$route.params.monedaDefecto,
          monedaProveedor: this.$route.params.monedaProveedor,
        };
      } else {
        const responseDetalle = await this.getFacturaDetalleCompletoProv(this.mProvId);
        if (responseDetalle.numeroExpediente > 0) {
          this.haveNumeroExpediente = true;
          this.numeroExpediente = responseDetalle.numeroExpediente;
        }
        this.vieneDeLiqVendedor = responseDetalle.vieneDeLiqVendedores;
        this.llevaImputYCc = responseDetalle.tipoCompLlevaImputCble;
        this.imputacionContableItems = responseDetalle.imputContablesList;
        this.centroCostosItems = responseDetalle.centrosCostosList;
        if(this.centroCostosItems != null){
          this.totalCentrosDeCostos = responseDetalle.centrosCostosList.reduce((sum, cc) => {
            return sum + cc.importe;},0);
        }
        if(this.imputacionContableItems != null){
          this.totalImputacionContable = responseDetalle.imputContablesList.reduce((sum, impCont) => {
            return sum + impCont.importe;}, 0); 
        }
        const dataMonedas = await this.getMonedasTaxonomy("habListado");
        this.monedasConsultaItems = dataMonedas;
        const responseParametros = await this.getParamGralMultimoneda();
        this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
        this.haveCotiza = false;
        const data = {
          mProvId: this.mProvId,
          //se agrego "?" para que no de error cuando viene de comprobantes proveedor sin moneda
          monedaId: this.monedaToDetalle?.id,
          fechaCotiza: this.fechaCotizaToDetalle,
        };
        if (this.fechaCotizaToDetalle != null) {
          this.fechaCotizacion = this.formatDate(this.fechaCotizaToDetalle);
          this.disableFechaCotiza = true;
          this.fechaSinCotiza = this.formatDate(this.fechaCotizaToDetalle);
        }
        const response = await this.getComprobanteProveedorById(data);
        this.itemsAlicuotasIVA = response.alicuotas;
        if (response.comprobantesCancelados.length != 0) {
          this.haveComprobCancel = true;
          this.comprobantesCanceladosList = response.comprobantesCancelados;
        }
        if (response.retenciones.length != 0) {
          this.haveListRetenciones = true;
          this.retencionesList = response.retenciones;
        }
        if (response.valores.length != 0) {
          this.haveListValores = true;
          this.valoresList = response.valores;
        }
        if (response.cabecera.nroCAE == "") {
          this.haveNumeroCAE = true;
        }
        if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
        if (response.cabecera.tipoEmision == "I") this.tipoEmision = "Impreso";
        else if (response.cabecera.tipoEmision == "E") this.tipoEmision = "Electrónico";
        else this.tipoEmision = "";
        this.proveedor = response.cabecera.proveedorNombre;
        this.cuit = response.cabecera.cuit;
        this.tipoIva = response.cabecera.tipoIvaNombre;
        this.tipoOperacion = response.cabecera.tipoOperacion;
        this.tipoComprobante = response.cabecera.tcompNombre;
        this.comprobanteNum = response.cabecera.compNro;
        this.entidadFacturante = response.cabecera.entFacNombre;
        this.periodo = response.cabecera.periodo;
        this.fecha = response.cabecera.fecha;
        this.fechaContable = response.cabecera.fechaContable;
        this.fechaVencimiento = response.cabecera.fechaVencimiento;
        this.fechaVencCAE = response.cabecera.fechaVtoCAE;
        this.monedaComprobante = response.cabecera.monedaNombre;
        this.cotizacionComprobante = response.cabecera.cotizacionComp;
        this.numeroCAE = response.cabecera.nroCAE;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        this.observacion = response.cabecera.observacion;
        this.cotizacionAlaFecha = response.cabecera.haveCotiza;
        this.monedaConsultaSelected = this.monedaToDetalle;
        if(this.monedaPorDefecto.id == this.monedaDeProveedor.id || 
        this.monedaDeProveedor.id == this.monedaToDetalle.id) this.disableFechaCotiza = false;
        if (this.monedaPorDefecto.id != this.monedaDeProveedor.id) this.disableTextImputCble = true;
        this.filtrosGuardados = {
          routeToGo: this.routeToGo,
          mProvId: this.mProvId,
          fechaCotizaToDetalle: this.fechaCotizaToDetalle,
          monedaToDetalle: this.monedaToDetalle,
          monedaDefecto: this.monedaPorDefecto,
          monedaProveedor: this.monedaDeProveedor,
        };
      }
    },
    async setComprobanteByMoneda() {
      if (this.$store.state.filtersDatosToEstadoFactu == undefined || this.$store.state.filtersDatosToEstadoFactu == null) {
        if (this.monedaConsultaSelected.id != this.$route.params.monedaDefecto.id && this.monedaConsultaSelected.id != this.$route.params.monedaProveedor.id) {
          if (this.$route.params.fechaCotizaToDetalle == null) {
            // fecha de hoy para seteo inicial de fecha cotiza
            let today = new Date();
            let año = today.getFullYear();
            let mes = today.getMonth() + 1;
            let dia = today.getDate();
            dia < 10 ? (dia = `0${dia}`) : dia;
            mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
            const fechaPrevia = `${año}-${mes}-${dia}`;
            this.fechaCotizacion = this.formatDate(fechaPrevia);
          } else {
            this.fechaCotizacion = this.formatDate(this.$route.params.fechaCotizaToDetalle);
          }
          this.disableFechaCotiza = true;
        } else {
          this.disableFechaCotiza = false;
          this.fechaCotizacion = null;
        }
        if (this.monedaConsultaSelected.id == this.$route.params.monedaDefecto.id) this.isMonedaDefecto = true;
        else this.isMonedaDefecto = false;
        this.haveCotiza = false;
        const data = {
          mProvId: this.$route.params.movProvId,
          monedaId: this.monedaConsultaSelected.id,
          fechaCotiza: this.$route.params.fechaCotizaToDetalle,
        };
        if (this.$route.params.fechaCotizaToDetalle == null) {
          let today = new Date();
          let año = today.getFullYear();
          let mes = today.getMonth() + 1;
          let dia = today.getDate();
          dia < 10 ? (dia = `0${dia}`) : dia;
          mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
          const fechaPrevia = `${año}-${mes}-${dia}`;
          this.fechaSinCotiza = this.formatDate(fechaPrevia);
        } else {
          this.fechaSinCotiza = this.formatDate(this.$route.params.fechaCotizaToDetalle);
        }
        const response = await this.getComprobanteProveedorById(data);
        if (response.comprobantesCancelados.length != 0) {
          this.haveComprobCancel = true;
          this.comprobantesCanceladosList = response.comprobantesCancelados;
        }
        if (response.retenciones.length != 0) {
          this.haveListRetenciones = true;
          this.retencionesList = response.retenciones;
        }
        if (response.valores.length != 0) {
          this.haveListValores = true;
          this.valoresList = response.valores;
        }
        if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
        this.itemsAlicuotasIVA = response.alicuotas;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        this.cotizacionAlaFecha = response.cabecera.haveCotiza;
      }else{
        if (this.monedaConsultaSelected.id != this.monedaPorDefecto.id && this.monedaConsultaSelected.id != this.monedaDeProveedor.id) {
          if (this.fechaCotizaToDetalle == null) {
            // fecha de hoy para seteo inicial de fecha cotiza
            let today = new Date();
            let año = today.getFullYear();
            let mes = today.getMonth() + 1;
            let dia = today.getDate();
            dia < 10 ? (dia = `0${dia}`) : dia;
            mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
            const fechaPrevia = `${año}-${mes}-${dia}`;
            this.fechaCotizacion = this.formatDate(fechaPrevia);
          } else {
            this.fechaCotizacion = this.formatDate(this.fechaCotizaToDetalle);
          }
          this.disableFechaCotiza = true;
        } else {
          this.disableFechaCotiza = false;
          this.fechaCotizacion = null;
        }
        if (this.monedaConsultaSelected.id == this.monedaPorDefecto.id) this.isMonedaDefecto = true;
        else this.isMonedaDefecto = false;
        this.haveCotiza = false;
        const data = {
          mProvId: this.mProvId,
          monedaId: this.monedaConsultaSelected.id,
          fechaCotiza: this.fechaCotizaToDetalle,
        };
        if (this.fechaCotizaToDetalle == null) {
          let today = new Date();
          let año = today.getFullYear();
          let mes = today.getMonth() + 1;
          let dia = today.getDate();
          dia < 10 ? (dia = `0${dia}`) : dia;
          mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
          const fechaPrevia = `${año}-${mes}-${dia}`;
          this.fechaSinCotiza = this.formatDate(fechaPrevia);
        } else {
          this.fechaSinCotiza = this.formatDate(this.fechaCotizaToDetalle);
        }
        const response = await this.getComprobanteProveedorById(data);
        if (response.comprobantesCancelados.length != 0) {
          this.haveComprobCancel = true;
          this.comprobantesCanceladosList = response.comprobantesCancelados;
        }
        if (response.retenciones.length != 0) {
          this.haveListRetenciones = true;
          this.retencionesList = response.retenciones;
        }
        if (response.valores.length != 0) {
          this.haveListValores = true;
          this.valoresList = response.valores;
        }
        if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
        this.itemsAlicuotasIVA = response.alicuotas;
        this.importeNeto = response.cabecera.neto;
        this.iva = response.cabecera.iva;
        this.percepcionIva = response.cabecera.percepcionIva;
        this.percepcionIIBB = response.cabecera.percepcionIB;
        this.impuestosInternos = response.cabecera.impuestosInternos;
        this.otrosConceptos = response.cabecera.otrosConceptos;
        this.importeTotal = response.cabecera.total;
        this.cotizacionAlaFecha = response.cabecera.haveCotiza;
      }
    },
    openModal() {
      this.$router.push({
        name: "EstadoFacturaPrestador",
        params: {
          movProvId:
            this.$route.params.movProvId == undefined
              ? this.mProvId
              : this.$route.params.movProvId,
        },
      });
      this.$store.state.filtersDatosToEstadoFactu = this.filtrosGuardados;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    closeDeleteModal() {
      this.$emit("closeModalDelete");
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(0, 0, 0);
}
.right-input >>> input {
  text-align: right;
}
.blue-text {
  color: blue;
  text-decoration: underline blue;
}
.btnDisabled {
  color: #100f0f99;
}
.label {
  opacity: 0.7;
}
.invalidConfigText {
  color: red;
}
</style>
