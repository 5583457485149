<template>
  <v-container class="pt-0">
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <PageHeader :title="title" class="pt-5" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="11" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplyed"
                  v-if="!showFilters && showFiltersCard"
                />
              </v-col>
              <v-col
                cols="12"
                md="1"
                align="right"
                align-self="center"
                class="py-0 mt-2 mb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text pr-1"
                      @click.stop="toggleFiltersSelected"
                      v-show="showFiltersCard"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
                <!-- </v-col>
              <v-col
                cols="12"
                md="1"
                align="right"
                align-self="center"
                class="py-0 mt-2 mb-2"
              > -->
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click="toggleShowFiltersCard"
                    >
                      {{ !showFiltersCard ? chevronDownIcon : chevronUpIcon }}
                    </v-icon>
                  </template>
                  <span>{{ !showFiltersCard ? "Mostrar" : "Esconder" }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters && showFiltersCard"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row class="pr-4">
                  <!-- Empresa -->
                  <v-col cols="12" md="3" class="py-0">
                    <v-autocomplete
                      ref="empresa-autocomplete"
                      v-model="empresaSelected"
                      :items="empresaItems"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                      autocomplete="off"
                      label="Entidad facturante"
                      hint="Nombre"
                      persistent-hint
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Proveedores -->
                  <v-col cols="12" md="2" class="p-0 pl-1 ">
                    <v-text-field
                      type="text"
                      outlined
                      clearable
                      dense
                      label="Código proveedor"
                      v-model.trim="provCod"
                      :rules="[rules.maxLength(provCod, 10)]"
                      @change="
                        {
                          buscarProveedor();
                        }
                      "
                      autocomplete="off"
                      hide-details=""
                    >
                      <template v-slot:append-outer>
                        <v-tooltip top max-width="50%">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              v-bind="attrs"
                              v-on="on"
                              @click="toggleModalBusquedaProv"
                            >
                              {{ searchIcon }}
                            </v-icon>
                          </template>
                          <span
                            >Buscar proveedor por nombre, CUIT o código</span
                          >
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- DATOS PROVEEDOR -->
                  <v-col cols="12" md="4" class="p-0 pl-1 ">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      disabled
                      :rules="rules.required"
                      readonly
                      label="Proveedor"
                      hide-details=""
                      :loading="proveedoresLoading"
                      v-model.trim="provNom"
                      autocomplete="off"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Tipo de fecha -->
                  <v-col cols="12" class="py-0" md="3">
                    <v-autocomplete
                      v-model="tipoFechaSelected"
                      :items="tipoFechaItems"
                      :rules="rules.required"
                      label="Tipo de fecha"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- fecha desde -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-desde"
                      v-model="menuFechaDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuFechaDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- fecha hasta -->
                  <v-col cols="6" md="3" class="py-0">
                    <v-menu
                      ref="fecha-hasta"
                      v-model="menuFechaHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            rules.required.concat(
                              rules.validDate,
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected)) ||
                                'Formato incorrecto'
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuFechaHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Monedas de consulta -->
                  <v-col
                    cols="3"
                    class="py-0"
                    md="3"
                    v-if="this.paramGralMultimoneda"
                  >
                    <v-autocomplete
                      v-model="monedaSelected"
                      :items="monedaItems"
                      :rules="rules.required"
                      @change="ocultarFechaCotiza"
                      label="Moneda de consulta"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- fecha Cotiza -->
                  <v-col
                    cols="6"
                    md="3"
                    class="py-0"
                    v-if="this.paramGralMultimoneda && this.disableFechaCotiza"
                  >
                    <v-menu
                      ref="fecha-cotiza"
                      v-model="menuFechaCotiza"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaCotizaSelected"
                          label="Fecha cotización"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaCotiza = parseDateToIso(fechaCotizaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaCotiza"
                        no-title
                        @change="fechaCotizaSelected = formatDate(fechaCotiza)"
                        @input="menuFechaCotiza = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- switch 1 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="facturaConVenc"
                      label="Solo factura con vencimiento al:"
                    ></v-switch>
                  </v-col>
                  <!-- Fecha de vencimiento -->
                  <v-col
                    cols="6"
                    md="3"
                    class="py-0 ml-n10"
                    v-if="this.facturaConVenc"
                  >
                    <v-menu
                      ref="fecha-vencimiento"
                      v-model="menuFechaVencimiento"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVencimientoSelected"
                          label="Fecha de vencimiento"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaVencimiento = parseDateToIso(
                              fechaVencimientoSelected
                            )
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-mask="'##/##/####'"
                          v-bind="attrs"
                          v-on="on"
                          :rules="rules.required.concat(rules.validDate)"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVencimiento"
                        no-title
                        @change="
                          fechaVencimientoSelected = formatDate(
                            fechaVencimiento
                          )
                        "
                        @input="menuFechaVencimiento = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- switch 3 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="incluirCompRelacion"
                      label="Incluir comprobantes relacionados"
                    ></v-switch>
                  </v-col>
                  <!-- switch 2 -->
                  <v-col cols="3" class="py-0 my-0">
                    <v-switch
                      class="mt-n1"
                      v-model="soloComprobanteConSaldo"
                      label="Solo comprobante con saldo"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    elevation="2"
                    small
                    type="submit"
                    :disabled="!isFormValid"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
            <p
              :class="
                showFilters
                  ? 'invalidConfigText mb-n1'
                  : 'invalidConfigText mb-n1 ml-2'
              "
              v-if="this.haveCotiza && this.paramGralMultimoneda"
            >
              Existen comprobantes sin cotización
            </p>
          </v-container>
        </v-card>
        <!-- tablas de informacion -->
        <v-card>
          <!-- tabla de comprobantes -->
          <v-data-table
            :headers="headersCompGral"
            dense
            :items="itemsComprobantes"
            height="100%"
            class="elevation-1"
            :loading="isLoadingTable"
            item-key="mProvId"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-row>
                  <v-col cols="12" md="9">
                    <v-toolbar-title class="primary--text"
                      >Comprobantes</v-toolbar-title
                    >
                  </v-col>
                  <v-col cols="12" md="3" class="text-right">
                    <v-menu
                      transition="scale-transition"
                      bottom
                      origin="center center"
                    >
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-btn v-bind="attrs" small color="primary" v-on="menu"
                          >Nuevo comprobante</v-btn
                        >
                      </template>

                      <v-list dense>
                        <v-list-item
                          v-for="item in compOptions"
                          :key="item.id"
                          link
                          @click="goToCargaFacturas()"
                        >
                          <v-list-item-title>{{
                            item.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToCargaFacturas(item.mProvId)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar comprobante</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobante(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="toggleComprobantesCancelatorios(item)"
                  >
                    {{ comprobanteCancelatorioIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobantes cancelatorios</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToDeleteComprobante(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar comprobante</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
        <v-card class="mt-4">
          <!-- tabla de comprobantes sin relacionar -->
          <v-data-table
            :headers="headersCompSinRelacionar"
            :items="itemsComprobantesSinRelacionar"
            height="100%"
            fixed-header
            class="elevation-1"
            dense
            :loading="isLoadingTable"
            item-key="mProvId"
          >
            <template v-slot:top>
              <v-toolbar flat dense>
                <v-toolbar-title class="primary--text"
                  >Comprobantes sin relacionar</v-toolbar-title
                >
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobante(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToDeleteComprobante(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar comprobante</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- saldos totales -->
    <v-row class="mt-3">
      <v-col cols="12" md="3" class="d-flex py-0">
        <v-text-field
          v-model.trim="saldoInicial"
          :label="saldoInicialView"
          outlined
          persistent-hint
          disabled
          filled
          readonly
          dense
          class="right-input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" class="d-flex py-0">
        <v-text-field
          v-model.trim="saldoFinal"
          :label="saldoFinalView"
          outlined
          persistent-hint
          disabled
          filled
          readonly
          dense
          class="right-input"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2"></v-col>
      <v-col cols="12" md="4" align="right" class="d-flex py-0 pl-10">
        <v-tooltip top max-width="40%">
          <template v-slot:activator="{ attrs }">
            <v-btn
              color="primary"
              class="fontsize"
              v-bind="attrs"
              outlined
              :disabled="itemsComprobantes.length == 0"
              @click="exportExcelModelo"
            >
              Exportar comprobantes
            </v-btn>
          </template>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-dialog
      v-if="openCompCancelatorios"
      v-model="openCompCancelatorios"
      max-width="60%"
      @keydown.esc="closeCompCancelatorios"
      persistent
    >
      <ComprobantesCancelatoriosProv
        @closeCompCancelatorios="closeCompCancelatorios"
        :compCancelItems="compCancelItems"
        :compId="compId"
        :monedaObject="monedaObject"
      ></ComprobantesCancelatoriosProv>
    </v-dialog>
    <!-- dialogs para eliminar -->
    <v-dialog
      v-if="openModalDeleteOp"
      v-model="openModalDeleteOp"
      max-width="60%"
      @keydown.esc="openModalDeleteOp = false"
      persistent
    >
      <VerOrdenDePago
        :isDelete="true"
        @closeModalDelete="(openModalDeleteOp = false), applyFilters()"
        :paramsToDeleteOp="paramsToDeleteOp"
      ></VerOrdenDePago>
    </v-dialog>
    <v-dialog
      v-if="openModalDelete"
      v-model="openModalDelete"
      max-width="65%"
      @keydown.esc="openModalDelete = false"
      persistent
    >
      <DetalleComprobanteProv
        :isDelete="true"
        @closeModalDelete="(openModalDelete = false), applyFilters()"
        :paramsToDelete="paramsToDelete"
      ></DetalleComprobanteProv>
    </v-dialog>
    <!-- MODAL BUSCAR PROVEEDOR -->
    <v-dialog
      v-model="buscarProveedorModal"
      v-if="buscarProveedorModal"
      @keydown.esc="toggleModalBusquedaProv"
      max-width="70%"
      persistent
    >
      <BuscarProveedor
        @toggleModalBusquedaProv="toggleModalBusquedaProv"
        @findProveedor="findProveedor"
      ></BuscarProveedor>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import { mapActions } from "vuex";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import BuscarProveedor from "@/components/modules/proveedores/BuscarProveedor.vue";
import ComprobantesCancelatoriosProv from "@/components/modules/proveedores/cuentaCorriente/ComprobantesCancelatoriosProv.vue";

import VerOrdenDePago from "@/components/modules/proveedores/ordenesPago/VerOrdenDePago.vue";
import DetalleComprobanteProv from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProv.vue";

export default {
  name: "CuentaCorrienteProveedores",
  directives: { mask },
  components: {
    PageHeader,
    FiltersSelected,
    Ayuda,
    ComprobantesCancelatoriosProv,
    VerOrdenDePago,
    DetalleComprobanteProv,
    BuscarProveedor
  },
  data() {
    return {
      title: "Cuenta corriente de proveedores",
      filtersApplyed: [],
      rules: rules,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      calendarIcon: enums.icons.CALENDAR,
      deleteIcon: enums.icons.DELETE,
      editIcon: enums.icons.EDIT,
      seeIcon: enums.icons.SEE,
      searchIcon: enums.icons.SEARCH,
      comprobanteCancelatorioIcon: enums.icons.FILE_CANCEL,
      optionCode: enums.webSiteOptions.CUENTA_CORRIENTE_PROVEEDORES,
      showHelp: false,
      showExpand: false,
      showFilters: true,
      showFiltersCard: true,
      isFormValid: false,
      isLoadingTable: false,
      openCompCancelatorios: false,
      buscarProveedorModal: false,
      monedaObject: null,
      allowedActions: null,
      filtersApplied: [],
      filtrosGuardados: {},
      empresaSelected: null,
      proveedorSelected: null,
      proveedoresLoading: false,
      provCod: null,
      provNom: null,
      provId: null,
      monedaProveedor: null,
      monedaDefecto: null,
      monedaSelected: null,
      tipoFechaSelected: null,
      monedaItems: [],
      empresaItems: [],
      proveedorItems: [],
      tipoFechaItems: [
        { id: 1, value: "Fecha contable", value2: "C" },
        { id: 2, value: "Fecha de comprobante", value2: "F" }
      ],
      compOptions: [
        {
          id: 1,
          title: "Factura | Nota de débito proveedor",
          esContado: false
        },
        { id: 2, title: "Recibo | Nota de crédito", esContado: true }
      ],
      menuFechaDesde: false,
      menuFechaHasta: false,
      menuFechaCotiza: false,
      menuFechaVencimiento: false,
      fechaDesdeSelected: null,
      fechaHastaSelected: null,
      fechaCotizaSelected: null,
      fechaVencimientoSelected: null,
      fechaDesde: null,
      fechaHasta: null,
      fechaCotiza: null,
      fechaVencimiento: null,
      soloComprobanteConSaldo: false,
      incluirCompRelacion: false,
      facturaConVenc: false,
      proveedorAutocompleteInput: null,
      paramGralMultimoneda: false,
      disableFechaCotiza: false,
      setInitialParameter: true,
      haveCotiza: false,
      saldoInicial: 0,
      saldoInicialView: "Saldo inicial",
      saldoFinalView: "Saldo final",
      saldoFinal: 0,
      comprobantesCancelatoriosItems: [],
      itemsComprobantesSinRelacionar: [],
      headersCompSinRelacionar: [
        {
          text: "Tipo comprobante",
          sortable: true,
          value: "tipoComp"
        },
        {
          text: "Número",
          sortable: true,
          value: "nroComp"
        },
        {
          text: "Fecha comprobante",
          sortable: true,
          value: "fecha"
        },
        {
          text: "Fecha contable",
          sortable: true,
          value: "fecContable"
        },
        {
          text: "Importe sin relacionar",
          sortable: true,
          value: "cancelado",
          align: "end"
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          width: "12%",
          align: "end"
        }
      ],
      itemsComprobantes: [],
      headersCompGral: [
        {
          text: "Tipo comprobante",
          sortable: true,
          width: "15%",
          align: "start",
          value: "tipoComp"
        },
        {
          text: "Número",
          width: "20%",
          align: "start",
          sortable: true,
          value: "nroComp"
        },
        {
          text: "Fecha comprobante",
          width: "15%",
          sortable: true,
          align: "start",
          value: "fecha",
          sort: (a, b) => {
            const parseDate = dateStr => {
              const [day, month, year] = dateStr.split("/");
              return new Date(year, month - 1, day);
            };
            return parseDate(a) - parseDate(b);
          }
        },
        {
          text: "Fecha contable",
          align: "start",
          width: "15%",
          sortable: true,
          value: "fechaContable",
          sort: (a, b) => {
            const parseDate = dateStr => {
              const [day, month, year] = dateStr.split("/");
              return new Date(year, month - 1, day);
            };
            return parseDate(a) - parseDate(b);
          }
        },
        {
          text: "Total",
          sortable: true,
          value: "total",
          width: "8%",

          sort: (a, b) => {
            const parseNumber = numberStr => {
              return parseFloat(numberStr.replaceAll(".", ""));
            };
            return parseNumber(a) - parseNumber(b);
          },
          align: "end"
        },
        {
          text: "Cancelado",
          sortable: true,
          width: "14%",
          value: "cancelado",
          sort: (a, b) => {
            const parseNumber = numberStr => {
              return parseFloat(numberStr.replaceAll(".", ""));
            };
            return parseNumber(a) - parseNumber(b);
          },
          align: "end"
        },
        {
          text: "Saldo",
          sortable: true,
          width: "8%",

          value: "saldo",
          sort: (a, b) => {
            const parseNumber = numberStr => {
              return parseFloat(numberStr.replaceAll(".", ""));
            };
            return parseNumber(a) - parseNumber(b);
          },
          align: "end"
        },
        {
          width: "5%",
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "end"
        }
      ],
      openModalDeleteOp: false,
      openModalDelete: false,
      paramsToDelete: null,
      paramsToDeleteOp: null,
      canDelete: false
    };
  },
  async created() {
    await this.$store.dispatch(
      "user/updateFrequencyByOptionCode", //aparece como pagina visitada si ingreso por url
      this.optionCode
    );
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPermisos();
    await this.setSelects();
    if (
      this.$route.params.empresaSelected &&
      this.$route.params.proveedorSelected
    ) {
      this.empresaSelected = this.$route.params.empresaSelected;
      this.provCod = this.$route.params.proveedorSelected.value2;
      await this.buscarProveedor();
      this.fechaDesde = this.$route.params.fechaDesde ?? this.fechaDesde;
      this.fechaHasta = this.$route.params.fechaHasta ?? this.fechaHasta;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      if(this.proveedorSelected != null){
        await this.applyFilters();
      }
    }
  },
  watch: {
    proveedorAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getProveedoresFilter();
      }
    }
  },
  async mounted() {
    if (this.$store.state.filtersProveedores != null) {
      this.empresaSelected = this.$store.state.filtersProveedores.empresa;
      this.provCod = this.$store.state.filtersProveedores.proveedor.value2;
      await this.buscarProveedor();
      this.fechaDesde = this.$store.state.filtersProveedores.fechaDesde;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = this.$store.state.filtersProveedores.fechaHasta;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      this.fechaCotiza = this.$store.state.filtersProveedores.fechaCotiza;
      this.fechaCotizaSelected = this.formatDate(this.fechaCotiza);
      this.tipoFechaSelected = this.$store.state.filtersProveedores.tipoFecha;
      this.fechaVencimiento = this.$store.state.filtersProveedores.fechaVencimiento;
      this.fechaVencimientoSelected = this.fechaVencimiento
        ? this.formatDate(this.fechaVencimiento)
        : null;
      this.soloComprobanteConSaldo = this.$store.state.filtersProveedores.compConSaldo;
      this.facturaConVenc = this.$store.state.filtersProveedores.soloFactura;
      this.incluirCompRelacion = this.$store.state.filtersProveedores.incluirCompRelacionados;
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      this.monedaItems = monedasResponse;
      if(this.$store.state.filtersProveedores.monedaId != null){
        const moneda = monedasResponse.find(
          x => x.id == this.$store.state.filtersProveedores.monedaId.id
        );
        this.monedaSelected = moneda;
      }
      this.monedaDefecto = this.$store.state.filtersProveedores.monedaDefecto;
      this.monedaProveedor = this.$store.state.filtersProveedores.monedaProveedor;
      this.disableFechaCotiza = this.$store.state.filtersProveedores.disableFechaCotiza;
      // this.monedaSelected = this.$store.state.filtersProveedores.monedaId;
      this.applyFilters();
    }
  },
  methods: {
    ...mapActions({
      fetchProveedoresByQuery: "proveedores/fetchProveedoresByQuery",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      getCtaCteProveedoresByFilter: "proveedores/getCtaCteProveedoresByFilter",
      getMonedaDeProveedor: "proveedores/getMonedaDeProveedor",
      getParametroGralFechaDesde: "proveedores/getParametroGralFechaDesde",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getProveedoresByCod: "afiliaciones/getProveedoresByCod"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_COMPROBANTE_CTACTE_PROVEEDORES:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    ocultarFechaCotiza(){
      if(this.monedaSelected.id != this.monedaDefecto.id && this.monedaSelected.id != this.monedaProveedor.id){
        if(!this.disableFechaCotiza){
          // fecha de hoy para seteo inicial de fecha cotiza
          let today = new Date();
          let año = today.getFullYear();
          let mes = today.getMonth() + 1;
          let dia = today.getDate();
          dia < 10 ? (dia = `0${dia}`) : dia;
          mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
          this.fechaCotiza = `${año}-${mes}-${dia}`;
          this.fechaCotizaSelected = this.formatDate(this.fechaCotiza);
        }
        this.disableFechaCotiza = true;
      }else{
        this.disableFechaCotiza = false;
        this.fechaCotiza = null;
        this.fechaCotizaSelected = null;
      }
    },
    async setMonedaProveedor(valor) {
      if (this.$store.state.filtersProveedores == null) {
        if (valor !== null) {
          this.monedaSelected = null;
          const response = await this.getMonedaDeProveedor(valor.id);
          const monedaToSelect = this.monedaItems.find(
            x => x.id == response.id
          );
          this.monedaProveedor = monedaToSelect;
          this.monedaSelected = monedaToSelect;
        } else {
          const monedasResponse = await this.getMonedasTaxonomy("habListado");
          this.monedaItems = monedasResponse;
          const monedaVig = monedasResponse.find(x => x.bool == true);
          if (monedaVig) this.monedaSelected = monedaVig;
        }
      }
    },
    async setSelects() {
      const entidadesFacturantes = await this.getEntidadesFacturantes();
      this.empresaItems = entidadesFacturantes;
      if (this.empresaItems.length == 1) {
        this.empresaSelected = this.empresaItems[0];
      }
      const monedasResponse = await this.getMonedasTaxonomy("habListado");
      this.monedaItems = monedasResponse;
      if (this.setInitialParameter) {
        //solo seteo para pantalla inicial el tipo fecha, la moneda vigente y fecha desde/hasta
        this.tipoFechaSelected = this.tipoFechaItems[1];
        const monedaVig = monedasResponse.find(x => x.bool == true);
        if (monedaVig) {
          this.monedaSelected = monedaVig;
          this.monedaDefecto = monedaVig;
        }
        this.setFechaInicial();
      }
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
    },
    async setFechaInicial() {
      // fecha de hoy para seteo inicial de fecha hasta y desde
      let today = new Date();
      let año = today.getFullYear();
      let mes = today.getMonth() + 1;
      let dia = today.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes; //para que el mes tenga dos digitos y lo reconozca el datepicker
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
      // seteo inicial de fecha desde
      const response = await this.getParametroGralFechaDesde();
      const FechaBaseDatos = response[0].fecDesdePorDefecto;
      if(FechaBaseDatos != ""){
        this.fechaDesde = this.parseDateToIso(FechaBaseDatos);
        this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      }else{
        let fechaDes = new Date(today);
        fechaDes.setDate(fechaDes.getDate() - 30);
        let añoDesde = fechaDes.getFullYear();
        let mesDesde = fechaDes.getMonth() + 1;
        let diaDesde = fechaDes.getDate();
        diaDesde < 10 ? (diaDesde = `0${diaDesde}`) : diaDesde;
        mesDesde < 10 ? (mesDesde = `0${mesDesde}`) : mesDesde;
        this.fechaDesde = `${añoDesde}-${mesDesde}-${diaDesde}`;
        this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      }
    },
    async applyFilters() {
      this.isLoadingTable = true;
      this.haveCotiza = false;
      this.setInitialParameter = false;
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      this.$store.state.filtersProveedores = null;
      this.customizeFiltersApplied();
      const filters = {
        entFacId: this.empresaSelected == null ? 0 : this.empresaSelected.id,
        provId: this.proveedorSelected.id,
        tipoFecha: this.tipoFechaSelected.value2,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        fechaVencimiento: this.facturaConVenc ? this.fechaVencimiento : null,
        compConSaldo: this.soloComprobanteConSaldo,
        incluirCompRelacionados: this.incluirCompRelacion,
        monedaId:
          this.monedaSelected && this.paramGralMultimoneda
            ? this.monedaSelected.id
            : null,
        fechaCotiza: this.paramGralMultimoneda ? this.fechaCotiza : null
      };
      this.filtrosGuardados = {
        empresa: this.empresaSelected,
        proveedor: this.proveedorSelected,
        tipoFecha: this.tipoFechaSelected,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        fechaVencimiento: this.fechaVencimiento,
        compConSaldo: this.soloComprobanteConSaldo,
        soloFactura: this.facturaConVenc,
        incluirCompRelacionados: this.incluirCompRelacion,
        monedaId:
          this.monedaSelected && this.paramGralMultimoneda
            ? this.monedaSelected
            : null,
        fechaCotiza: this.paramGralMultimoneda ? this.fechaCotiza : null,
        monedaProveedor: this.monedaProveedor,
        monedaDefecto: this.monedaDefecto,
        disableFechaCotiza: this.disableFechaCotiza
      };
      try {
        const response = await this.getCtaCteProveedoresByFilter(filters);
        this.customizeFiltersApplied();
        this.showFilters = false;
        this.itemsComprobantes = response.comprobantes;
        this.itemsComprobantesSinRelacionar = response.comprobantesSinRela;
        this.comprobantesCancelatoriosItems =
          response.comprobantesCancelatorios;
        this.saldoInicialView = `Saldo inicial al: ${this.formatDate(
          this.fechaDesde
        )}`;
        this.saldoFinalView = `Saldo final al: ${this.formatDate(
          this.fechaHasta
        )}`;
        const anyDeudaWithCotiza = this.itemsComprobantes.some(
          x => x.haveCotiza == 0
        );
        const anyCancelaWithCotiza = this.comprobantesCancelatoriosItems.some(
          x => x.haveCotiza == 0
        );
        const anySinRelaaWithCotiza = this.itemsComprobantesSinRelacionar.some(
          x => x.haveCotiza == 0
        );
        if (anyDeudaWithCotiza || anyCancelaWithCotiza || anySinRelaaWithCotiza)
          this.haveCotiza = true;
        this.saldoInicial = response.saldoInicial;
        this.saldoFinal = response.saldoFinal;
        this.isLoadingTable = false;
      } catch {
        this.isLoadingTable = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplyed = [];
      if (this.empresaSelected) {
        this.filtersApplyed.splice(0, 1, {
          key: "empresaSelected",
          label: "Entidad facturante",
          model: this.empresaSelected.value
        });
      }
      if (this.proveedorSelected) {
        this.filtersApplyed.splice(1, 1, {
          key: "proveedorSelected",
          label: "Proveedor",
          model: this.proveedorSelected.value1
        });
      }
      if (this.tipoFechaSelected) {
        this.filtersApplyed.splice(2, 1, {
          key: "tipoFechaSelected",
          label: "Tipo de fecha",
          model: this.tipoFechaSelected.value
        });
      }
      if (this.fechaDesde) {
        this.filtersApplyed.splice(3, 1, {
          key: "fechaDesde",
          label: "Vigencia desde",
          model: this.formatDate(this.fechaDesde)
        });
      }
      if (this.fechaHasta) {
        this.filtersApplyed.splice(4, 1, {
          key: "fechaHasta",
          label: "Vigencia hasta",
          model: this.formatDate(this.fechaHasta)
        });
      }
      if (this.monedaSelected && this.paramGralMultimoneda) {
        this.filtersApplyed.splice(5, 1, {
          key: "monedaSelected",
          label: "Moneda de consulta",
          model: this.monedaSelected.value
        });
      }
      if (this.fechaCotiza && this.paramGralMultimoneda) {
        this.filtersApplyed.splice(6, 1, {
          key: "fechaCotiza",
          label: "Fecha cotización",
          model: this.formatDate(this.fechaCotiza)
        });
      }
      if (this.soloComprobanteConSaldo || !this.soloComprobanteConSaldo) {
        this.filtersApplyed.splice(7, 1, {
          key: "soloComprobanteConSaldo",
          label: "Solo comprobante con saldo",
          model: this.soloComprobanteConSaldo ? "SI" : "NO"
        });
      }
      if (this.incluirCompRelacion || !this.incluirCompRelacion) {
        this.filtersApplyed.splice(8, 1, {
          key: "incluirCompRelacion",
          label: "Incluir comprobantes relacionados",
          model: this.incluirCompRelacion ? "SI" : "NO"
        });
      }
      if (this.facturaConVenc || !this.facturaConVenc) {
        this.filtersApplyed.splice(9, 1, {
          key: "facturaConVenc",
          label: this.facturaConVenc
            ? "Solo factura con vencimiento al"
            : "Solo factura con vencimiento",
          model: this.facturaConVenc
            ? this.formatDate(this.fechaVencimiento)
            : "NO"
        });
      }
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    toggleShowFiltersCard() {
      this.showFiltersCard = !this.showFiltersCard;
    },

    resetForm() {
      this.$refs["filters-form"].reset();
    },
    goToCargaFacturas(mProvId) {
      this.$router.push({
        name: "CargaFacturasProveedores",
        params: {
          mProvId: mProvId,
          empresaSelected: this.empresaSelected
            ? this.empresaSelected.id
            : null,
          provCod: this.proveedorSelected
            ? this.proveedorSelected.value2
            : null,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta
        }
      });
    },
    goToVerComprobante(item) {
      if (item.esOp) {
        this.$router.push({
          name: "VerOrdenDePago",
          params: {
            movProvId: item.mProvId,
            isCtaCte: true,
            monedaToDetalle: this.filtrosGuardados.monedaId,
            fechaCotizaToDetalle: this.filtrosGuardados.fechaCotiza,
            goBackName: "CuentaCorrienteProveedores"
          }
        });
      } else {
        this.$router.push({
          name: "DetalleComprobanteProv",
          params: {
            movProvId: item.mProvId,
            monedaToDetalle: this.filtrosGuardados.monedaId,
            fechaCotizaToDetalle: this.filtrosGuardados.fechaCotiza,
            monedaDefecto: this.monedaDefecto,
            monedaProveedor: this.monedaProveedor,
            goBackName: "CuentaCorrienteProveedores",
          },
        });
      }
      this.$store.state.filtersProveedores = this.filtrosGuardados;
    },
    goToDeleteComprobante(item) {
      this.paramsToDelete = {
        movProvId: item.mProvId,
        isCtaCte: true,
        monedaToDetalle: this.monedaSelected,
        fechaCotizaToDetalle: this.fechaCotiza
      };
      if (item.esOp) {
        this.paramsToDeleteOp = this.paramsToDelete;
        this.paramsToDeleteOp.isCtaCte = false;
        this.openModalDeleteOp = true;
      } else this.openModalDelete = true;
    },
    toggleComprobantesCancelatorios(item) {
      this.openCompCancelatorios = true;
      this.compCancelItems = this.comprobantesCancelatoriosItems;
      this.compId = item.mProvId;
      this.monedaObject = {
        monedaToDetalle: this.filtrosGuardados.monedaId,
        fechaCotizaToDetalle: this.filtrosGuardados.fechaCotiza
      };
      this.$store.state.filtersProveedores = this.filtrosGuardados;
    },
    closeCompCancelatorios() {
      this.openCompCancelatorios = false;
    },
    toggleModalBusquedaProv() {
      this.buscarProveedorModal = !this.buscarProveedorModal;
    },
    async findProveedor(proveedor) {
      this.proveedorSelected = {
        id: proveedor.provId,
        value1: proveedor.provNom,
        value2: proveedor.provCod
      };
      this.provId = this.proveedorSelected.provId;
      this.provNom = proveedor.provNom;
      this.provCod = proveedor.provCod;
      await this.setMonedaProveedor(this.proveedorSelected);
    },
    async buscarProveedor() {
      if (
        this.provCod !== null &&
        this.provCod !== undefined &&
        this.provCod !== ""
      ) {
        try {
          this.proveedoresLoading = true;
          this.proveedores = await this.getProveedoresByCod(this.provCod);
          if (this.proveedores.length === 0) {
            this.setAlert({
              type: "warning",
              message: "Proveedor inexistente"
            });
            this.proveedorSelected = null;
            this.provNom = null;
            this.proveedoresLoading = false;
            return;
          }
          this.proveedoresLoading = false;
          this.proveedorSelected = this.proveedores[0];
          this.provId = this.proveedorSelected.id;
          this.provNom = this.proveedorSelected.value1;
          this.cuit = this.proveedorSelected.cuit;
          await this.setMonedaProveedor(this.proveedorSelected);
        } catch (error) {
          this.proveedoresLoading = false;
          this.proveedorSelected = null;
          this.provId = null;
        }
      } else {
        this.proveedorSelected = null;
        this.provId = null;
        this.provNom = null;
      }
    },
    getProveedoresFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.proveedorItems?.some(
            x =>
              x.value2 == this.proveedorAutocompleteInput ||
              x.value1 == this.proveedorAutocompleteInput
          )
        ) {
          return;
        } else {
          this.proveedoresLoading = true;
          const response = await this.fetchProveedoresByQuery({
            input: this.proveedorAutocompleteInput
          });
          this.proveedorItems = response;
          this.proveedoresLoading = false;
        }
      }, 1000);
    },
    customFilterProveedores(item, queryText, itemText) {
      return item.value1 || item.value2.indexOf(queryText) > -1;
    },
    exportExcelModelo() {
      let result = [];
      //armo el objeto para cada comp y lo voy pusheando a result
      this.itemsComprobantes?.forEach(x => {
        const objetoComprobanteDeuda = {
          ["Entidad facturante"]: x.entFacNombre,
          ["Proveedor"]: x.provNombre,
          ["Tipo de comprobante"]: x.tipoComp,
          ["Número"]: x.nroComp,
          ["Fecha de comprobante"]: x.fecha,
          ["Fecha contable"]: x.fechaContable,
          ["Fecha de vencimiento"]: x.fechaVencimiento,
          ["Total"]: x.total,
          ["Cancelado"]: x.cancelado,
          ["Saldo"]: x.saldo,
          ["Periodo"]: x.periodo,
          ["Detalle"]: "Comprobante"
        };
        // Verifica si parametro gral multimoneda esta activado para insertar ciertos datos
        if (this.paramGralMultimoneda) {
          objetoComprobanteDeuda["Moneda del comprobante"] = x.monedaNombre;
          objetoComprobanteDeuda[
            "Moneda de consulta"
          ] = this.filtrosGuardados.monedaId.value;
          objetoComprobanteDeuda["Fecha cotización"] = this.formatDate(
            this.filtrosGuardados.fechaCotiza
          );
        }
        result.push(objetoComprobanteDeuda);
      });
      this.itemsComprobantesSinRelacionar?.forEach(x => {
        const objetoComprobanteSinRela = {
          ["Entidad facturante"]: x.entFacNombre,
          ["Proveedor"]: x.provNombre,
          ["Tipo de comprobante"]: x.tipoComp,
          ["Número"]: x.nroComp,
          ["Fecha de comprobante"]: x.fecha,
          ["Fecha contable"]: x.fecContable,
          ["Total"]: "0.00",
          ["Cancelado"]: x.cancelado,
          ["Saldo"]: "0.00",
          ["Periodo"]: x.periodo,
          ["Detalle"]: "Comprobante sin relacionar"
        };
        // Verifica si parametro gral multimoneda esta activado para insertar ciertos datos
        if (this.paramGralMultimoneda) {
          objetoComprobanteSinRela["Moneda del comprobante"] = x.monedaNombre;
          objetoComprobanteSinRela[
            "Moneda de consulta"
          ] = this.filtrosGuardados.monedaId.value;
          objetoComprobanteSinRela["Fecha cotización"] = this.formatDate(
            this.filtrosGuardados.fechaCotiza
          );
        }
        result.push(objetoComprobanteSinRela);
      });
      this.comprobantesCancelatoriosItems?.forEach(x => {
        const objetoComprobanteCancela = {
          ["Entidad facturante"]: x.entFacNombre,
          ["Proveedor"]: x.provNombre,
          ["Tipo de comprobante"]: x.tipoComp,
          ["Número"]: x.nroComp,
          ["Fecha de comprobante"]: x.fecha,
          ["Fecha contable"]: x.fecContable,
          ["Total"]: "0.00",
          ["Cancelado"]: x.cancelado,
          ["Saldo"]: "0.00",
          ["Periodo"]: x.periodo,
          ["Detalle"]: "Comprobante cancelatorio"
        };
        // Verifica si parametro gral multimoneda esta activado para insertar ciertos datos
        if (this.paramGralMultimoneda) {
          objetoComprobanteCancela["Moneda del comprobante"] = x.monedaNombre;
          objetoComprobanteCancela[
            "Moneda de consulta"
          ] = this.filtrosGuardados.monedaId.value;
          objetoComprobanteCancela["Fecha cotización"] = this.formatDate(
            this.filtrosGuardados.fechaCotiza
          );
        }
        result.push(objetoComprobanteCancela);
      });
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Comprobantes de proveedor");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(56, 56, 56);
}
.right-input >>> input {
  text-align: right;
}
.invalidConfigText {
  color: red;
}
</style>
