<template>
  <v-card class="px-5">
    <v-col>
      <v-card >
        <v-card-text>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-card>
                <v-card-title class="h6">Proveedor</v-card-title>
                <v-card-text>
                  <v-row class="mb-n7">
                    <!-- Proveedor -->
                    <v-col cols="3" md="3" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Proveedor"
                        v-model="proveedor"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- CUIT -->
                    <v-col cols="3" md="3" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="CUIT"
                        v-model="cuit"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Tipo IVA -->
                    <v-col cols="3" md="3" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Condición de IVA"
                        v-model="tipoIva"
                        autocomplete="off"
                                              ></v-text-field>
                    </v-col>
                    <!-- Tipo de operacion -->
                    <v-col cols="3" md="3" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Tipo de operación"
                        v-model="tipoOperacion"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-card>
                <v-card-title class="h6 mt-n2">Comprobante</v-card-title>
                <v-card-text>
                  <v-row class="mb-n2">
                    <!-- Entidad facturante -->
                    <v-col cols="4" md="4" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Entidad facturante"
                        v-model="entidadFacturante"
                        autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <!-- Periodo -->
                    <v-col cols="4" md="2" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Período"
                        v-model="periodo"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                    <!-- Tipo de comprobante -->
                    <v-col cols="4" md="3" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Tipo de comprobante"
                        v-model="tipoComprobante"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                    <!-- Comprobante numero -->
                    <v-col cols="4" sm="4" md="3" class="py-0">
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Comprobante número"
                        v-model="comprobanteNum"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mb-n7">
                    <!-- Fecha -->
                    <v-col
                      cols="2"
                      :md="this.paramGralMultimoneda ? 2 : 3"
                      class="py-0"
                    >
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Fecha"
                        v-model="fecha"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                    <!-- Fecha contable -->
                    <v-col
                      cols="2"
                      :md="this.paramGralMultimoneda ? 2 : 3"
                      class="py-0"
                    >
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Fecha contable"
                        v-model="fechaContable"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                    <!-- Fecha vencimiento -->
                    <v-col
                      cols="2"
                      :md="this.paramGralMultimoneda ? 2 : 3"
                      class="py-0"
                    >
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Fecha de vencimiento"
                        v-model="fechaVencimiento"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                    <!-- Moneda del comprobante -->
                    <v-col
                      cols="12"
                      md="2"
                      class="py-0"
                      v-if="this.paramGralMultimoneda"
                    >
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Moneda del comp."
                        v-model="monedaComprobante"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                    <!-- Fecha de cotizacion -->
                    <v-col
                      cols="2"
                      :md="this.paramGralMultimoneda ? 2 : 3"
                      v-if="this.paramGralMultimoneda"
                      class="py-0"
                    >
                      <v-text-field
                        type="text"
                        outlined
                        dense
                        filled
                        readonly
                        label="Fecha cotización"
                        v-model="fechaCotizacion"
                        autocomplete="off"
                        
                      ></v-text-field>
                    </v-col>
                    <!-- Moneda de consulta -->
                    <v-col
                      cols="12"
                      md="2"
                      class="py-0"
                      v-if="this.paramGralMultimoneda"
                    >
                      <v-autocomplete
                        v-model="monedaConsultaSelected"
                        :items="monedasConsultaItems"
                        item-text="value"
                        item-value="id"
                        return-object
                        label="Moneda de consulta"
                        @change="setComprobanteByMoneda()"
                        outlined
                        dense
                      >
                      </v-autocomplete>
                      <p class="invalidConfigText mt-n5" v-if="this.haveCotiza">
                        Sin cotización
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
                <!-- tabla de detalles alicuotas IVA -->
                <v-data-table
                  :headers="headersAlicuotasIVA"
                  :items="itemsAlicuotasIVA"
                  height="100%"
                  fixed-header
                  class="elevation-1"
                  :loading="isLoadingTable"
                  hide-default-footer
                >
                </v-data-table>
                <v-row class="mb-n2 mt-3">
                  <!-- Neto -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      prepend-inner-icon="mdi-currency-usd"
                      class="right-input"
                      readonly
                      label="Neto"
                      v-model="importeNeto"
                      autocomplete="off"
                      
                    ></v-text-field>
                  </v-col>
                  <!-- IVA -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      readonly
                      prepend-inner-icon="mdi-currency-usd"
                      label="IVA"
                      v-model="iva"
                      class="right-input"
                      autocomplete="off"
                      
                    ></v-text-field>
                  </v-col>
                  <!-- Percepción IVA -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      readonly
                      prepend-inner-icon="mdi-currency-usd"
                      label="Percepción IVA"
                      class="right-input"
                      v-model="percepcionIva"
                      autocomplete="off"
                      
                    ></v-text-field>
                  </v-col>
                  <!-- Percepción IIBB -->
                  <v-col cols="3" sm="3" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      prepend-inner-icon="mdi-currency-usd"
                      readonly
                      class="right-input"
                      label="Percepción IIBB"
                      v-model="percepcionIIBB"
                      autocomplete="off"
                      
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mb-n7">
                  <!-- Impuestos internos -->
                  <v-col cols="4" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      prepend-inner-icon="mdi-currency-usd"
                      filled
                      readonly
                      class="right-input"
                      label="Impuestos internos"
                      v-model="impuestosInternos"
                      autocomplete="off"
                      
                    ></v-text-field>
                  </v-col>
                  <!-- Otros conceptos -->
                  <v-col cols="4" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      prepend-inner-icon="mdi-currency-usd"
                      dense
                      filled
                      readonly
                      class="right-input"
                      label="Otros conceptos"
                      v-model="otrosConceptos"
                      autocomplete="off"
                      
                    ></v-text-field>
                  </v-col>
                  <!-- Total -->
                  <v-col cols="4" md="3" class="py-0">
                    <v-text-field
                      type="text"
                      outlined
                      dense
                      filled
                      readonly
                      prepend-inner-icon="mdi-currency-usd"
                      class="right-input"
                      label="Total"
                      v-model="importeTotal"
                      autocomplete="off"
                      
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-col cols="12" v-if="this.haveComprobCancel">
          <v-card>
            <v-card-title class="h6">
              Listado de comprobantes cancelados
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headersComprobantesCancelados"
                    :items="comprobantesCanceladosList"
                    class="elevation-1"
                    hide-default-footer
                    :items-per-page="999"
                    dense
                  >
                  </v-data-table
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="this.haveListRetenciones">
          <v-card>
            <v-card-title class="h6"> Listado de retenciones </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headersRetenciones"
                    :items="retencionesList"
                    :items-per-page="999"
                    class="elevation-1"
                    hide-default-footer
                    dense
                  >
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" v-if="this.haveListValores">
          <v-card>
            <v-card-title class="h6"> Listado de valores </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headersValores"
                    :items="valoresList"
                    class="elevation-1"
                    :items-per-page="999"
                    hide-default-footer
                    dense
                  >
                  </v-data-table
                ></v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- datos del CAE -->
        <v-row class="ml-1 mb-n5">
          <v-col cols="4" class="d-flex" v-if="!this.haveNumeroCAE">
            <p class="label">Número de CAE/CAI:</p>
            <strong class="ml-2 mb-0">{{ numeroCAE }}</strong>
          </v-col>
          <v-col cols="4" class="d-flex" v-if="!this.haveNumeroCAE">
            <p class="label">Fecha de vencimiento CAE/CAI:</p>
            <strong class="ml-2 mb-0">{{ fechaVencCAE }}</strong>
          </v-col>
          <v-col cols="3" class="d-flex">
            <p class="label">Tipo de emisión:</p>
            <strong class="ml-2 mb-0">{{ tipoEmision }}</strong>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex py-0 ml-5">
            <p class="label">Observación:</p>
            <strong class="ml-2 mb-0">{{ observacion }}</strong>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-card-actions class="pr-3">
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeDeleteModal()"> Cancelar </v-btn>
      <v-btn color="primary" @click="validateDeleteComp()"> Eliminar </v-btn>
    </v-card-actions>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'30%'"
      :textDelete="textDelete"
      :isLoading="loadingBtnDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="secondConfirmDelete()"
    />
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
      @onConfirm="secondConfirmDelete()"
      @update:openConfirm="cancelarDialog"
      :submitBtn="'ELIMINAR'"
      :cancelBtn="'CANCELAR'"
    />
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "DetalleComprobanteProvEliminar",
  components: {
    DeleteDialog,
    ConfirmDialog,
  },
  props: {
    paramsToDelete: { type: Object, required: false, default: null },
  },
  created() {
    this.setDetalleComprobante();
  },
  data() {
    return {
      loadingBtnDelete: false,
      titleDelete: enums.titles.ELIMINAR_COMPROBANTE,
      showDeleteModal: false,
      proveedor: null,
      cuit: null,
      openConfirmDialog: false,
      textConfirmDialog: "",
      tipoIva: null,
      tipoOperacion: null,
      tipoComprobante: null,
      comprobanteNum: null,
      entidadFacturante: null,
      periodo: null,
      fecha: null,
      textDelete: null,
      fechaContable: null,
      fechaVencimiento: null,
      fechaVencCAE: null,
      numeroCAE: null,
      tipoEmision: null,
      importeNeto: null,
      iva: null,
      percepcionIva: null,
      percepcionIIBB: null,
      impuestosInternos: null,
      otrosConceptos: null,
      importeTotal: null,
      observacion: null,
      monedaComprobante: null,
      monedaConsultaSelected: null,
      fechaCotizacion: null,
      monedasConsultaItems: [],
      haveComprobCancel: false,
      haveListRetenciones: false,
      haveNumeroCAE: false,
      haveListValores: false,
      isLoadingTable: false,
      paramGralMultimoneda: false,
      haveCotiza: false,
      comprobantesCanceladosList: [],
      headersComprobantesCancelados: [
        {
          text: "Fecha",
          value: "fecha",
          sortable: false,
          align: "start",
        },
        {
          text: "Tipo comprobante",
          value: "tipoCompNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Comprobante",
          value: "compNro",
          sortable: false,
          align: "start",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado",
          value: "importeCancelado",
          sortable: false,
          align: "end",
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          align: "end",
        },
        {
          text: "Cancelado O.P.",
          value: "canceladoOP",
          sortable: false,
          align: "end",
        },
      ],
      retencionesList: [],
      headersRetenciones: [
        {
          text: "Retención",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
      valoresList: [],
      headersValores: [
        {
          text: "Valor",
          value: "tipoValorNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Número",
          value: "numero",
          sortable: false,
          align: "start",
        },
        {
          text: "Fecha",
          value: "fechaValor",
          sortable: false,
          align: "start",
        },
        {
          text: "Banco",
          value: "bancoNombre",
          sortable: false,
          align: "start",
        },
        {
          text: "Importe",
          value: "importe",
          sortable: false,
          align: "end",
        },
      ],
      itemsAlicuotasIVA: [],
      headersAlicuotasIVA: [
        {
          text: "Concepto",
          sortable: false,
          value: "concepto",
        },
        {
          text: "Alícuota de IVA",
          sortable: false,
          value: "condicionIVA",
        },
        {
          text: "Porcentaje de IVA",
          sortable: false,
          value: "ivaPorc",
          align: "end",
        },
        {
          text: "Neto",
          sortable: false,
          value: "neto",
          align: "end",
        },
        {
          text: "IVA",
          sortable: false,
          value: "iva",
          align: "end",
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
          align: "end",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getComprobanteProveedorById: "proveedores/getComprobanteProveedorById",
      getParamGralMultimoneda: "AdministracionSistema/getParamGralMultimoneda",
      getMonedasTaxonomy: "AdministracionSistema/getMonedasTaxonomy",
      deleteComprobantesProveedor: "proveedores/deleteComprobantesProveedor",
      validateDeleteComprobante: "proveedores/validateDeleteComprobante",
      setAlert: "user/setAlert",
    }),
    async validateDeleteComp() {
      try {
        const response = await this.validateDeleteComprobante(
          this.paramsToDelete.movProvId
        );
        if (response.tieneAdvertencia) {
          this.loadingBtnDelete = false;
          this.showDeleteModal = false;
          this.openConfirmDialog = true;
          this.textConfirmDialog = `${response.mensajeValidacion}¿Desea eliminar de todas formas?`;
        } else {
          if (response.mensajeValidacion == null) {
            //abrir dialog confirmacion
            this.showDeleteModal = true;
          } else {
            this.setAlert({
              type: "warning",
              message: response.mensajeValidacion,
            });
            this.loadingBtnDelete = false;
            this.showDeleteModal = false;
          }
        }
      } catch (err) {}
    },
    async secondConfirmDelete() {
      try {
        this.loadingBtnDelete = true;
        const response = await this.deleteComprobantesProveedor(
          this.paramsToDelete.movProvId
        );
        this.setAlert({
          type: response === "Comprobante Eliminado" ? "success" : "error",
          message:
            response === "Comprobante Eliminado"
              ? "Comprobante eliminado con éxito."
              : response,
        });
      } catch (err) {}
      this.loadingBtnDelete = false;
      this.showDeleteModal = false;
      this.closeDeleteModal();
    },
    async setDetalleComprobante() {
      const dataMonedas = await this.getMonedasTaxonomy("habListado");
      this.monedasConsultaItems = dataMonedas;
      const responseParametros = await this.getParamGralMultimoneda();
      this.paramGralMultimoneda = responseParametros[0].multimonedaParams;
      this.haveCotiza = false;
      const data = {
        mProvId: this.paramsToDelete.movProvId,
        //se agrego "?" para que no de error cuando viene de comprobantes proveedor sin moneda
        monedaId: this.paramsToDelete.monedaToDetalle?.id,
        fechaCotiza: this.paramsToDelete.fechaCotizaToDetalle,
      };
      this.fechaCotizacion = this.formatDate(
        this.paramsToDelete.fechaCotizaToDetalle
      );
      const response = await this.getComprobanteProveedorById(data);
      this.itemsAlicuotasIVA = response.alicuotas;
      if (response.comprobantesCancelados.length != 0) {
        this.haveComprobCancel = true;
        this.comprobantesCanceladosList = response.comprobantesCancelados;
      }
      if (response.retenciones.length != 0) {
        this.haveListRetenciones = true;
        this.retencionesList = response.retenciones;
      }
      if (response.valores.length != 0) {
        this.haveListValores = true;
        this.valoresList = response.valores;
      }
      if (response.cabecera.nroCAE == "") {
        this.haveNumeroCAE = true;
      }
      if (response.cabecera.haveCotiza == 0) this.haveCotiza = true;
      if (response.cabecera.tipoEmision == "I") this.tipoEmision = "Impreso";
      else if (response.cabecera.tipoEmision == "E")
        this.tipoEmision = "Electrónico";
      else this.tipoEmision = "";
      this.proveedor = response.cabecera.proveedorNombre;
      this.cuit = response.cabecera.cuit;
      this.tipoIva = response.cabecera.tipoIvaNombre;
      this.tipoOperacion = response.cabecera.tipoOperacion;
      this.tipoComprobante = response.cabecera.tcompNombre;
      this.comprobanteNum = response.cabecera.compNro;
      this.entidadFacturante = response.cabecera.entFacNombre;
      this.periodo = response.cabecera.periodo;
      this.fecha = response.cabecera.fecha;
      this.fechaContable = response.cabecera.fechaContable;
      this.fechaVencimiento = response.cabecera.fechaVencimiento;
      this.fechaVencCAE = response.cabecera.fechaVtoCAE;
      this.monedaComprobante = response.cabecera.monedaNombre;
      this.numeroCAE = response.cabecera.nroCAE;
      this.importeNeto = response.cabecera.neto;
      this.iva = response.cabecera.iva;
      this.percepcionIva = response.cabecera.percepcionIva;
      this.percepcionIIBB = response.cabecera.percepcionIB;
      this.impuestosInternos = response.cabecera.impuestosInternos;
      this.otrosConceptos = response.cabecera.otrosConceptos;
      this.importeTotal = response.cabecera.total;
      this.observacion = response.cabecera.observacion;
      this.monedaConsultaSelected = this.paramsToDelete.monedaToDetalle;
    },
    closeDeleteModal() {
      this.$emit("closeModalDelete");
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    cancelarDialog() {
      this.textConfirmDialog = "";
    },
  },
};
</script>

<style scoped>
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgb(0, 0, 0);
}
.right-input >>> input {
  text-align: right;
}
.invalidConfigText {
  color: red;
}
</style>