<template>
  <v-card>
    <v-container fluid>
      <v-layout>
        <v-flex>
          <v-data-table
            :headers="headers"
            item-key="mProvId"
            hide-default-footer
            :items="comprobantesItems"
            :loading="loadingTable"
            loading-text="Cargando datos"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row class="pb-0">
                  <v-col cols="12" md="10" align="start" class="pt-0">
                    <v-card-title class="pl-1 primary--text">{{
                      title
                    }}</v-card-title>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToVerComprobante(item)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver comprobante</span>
              </v-tooltip>
              <v-tooltip v-if="canDelete" left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="goToDeleteComprobante(item)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar comprobante</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-row>
            <v-col cols="12" class="text-right" align="end">
              <v-btn outlined @click="closeModal"> Cerrar </v-btn>
            </v-col>
          </v-row>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
      v-if="openModalDelete"
      v-model="openModalDelete"
      max-width="65%"
      @keydown.esc="openModalDelete = false"
      persistent
    >
      <DetalleComprobanteProv
        :isDelete="true"
        @closeModalDelete="openModalDelete = false"
        :paramsToDelete="paramsToDelete"
      ></DetalleComprobanteProv>
    </v-dialog>
  </v-card>
</template>

<script>
import enums from "@/utils/enums/index.js";
import DetalleComprobanteProv from "@/components/modules/proveedores/cuentaCorriente/DetalleComprobanteProv.vue";

export default {
  name: "ComprobantesCancelatoriosProv",
  components: {
    DetalleComprobanteProv,
  },
  props: {
    compId: {
      type: Number,
      required: false,
      default: null,
    },
    compCancelItems: {
      type: Array,
      required: false,
      default: [],
    },
    monedaObject: {
      type: Object,
      required: false,
      default: [],
    },
  },
  data() {
    return {
      title: `Comprobantes cancelatorios`,
      seeIcon: enums.icons.SEE,
      deleteIcon: enums.icons.DELETE,
      canDelete: false,
      paramsToDelete: null,
      openModalDelete: false,
      loadingTable: false,
      comprobantesItems: [],
      headers: [
        {
          text: "Tipo de comprobante",
          align: "start",
          sortable: false,
          value: "tipoComp",
        },
        {
          text: "Número",
          sortable: false,
          value: "nroComp",
        },
        {
          text: "Fecha",
          sortable: false,
          value: "fecha",
        },
        {
          text: "Fecha contable",
          sortable: false,
          value: "fecContable",
        },
        {
          text: "Importe cancelado",
          sortable: false,
          value: "cancelado",
          align: "end",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      allowedActions: null,
    };
  },
  created() {
    this.setPermisos();
    this.setComprobantesCancelatorios();
  },
  methods: {
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .ELIMINAR_COMPROBANTE_CTACTE_PROVEEDORES:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async setComprobantesCancelatorios() {
      this.loadingTable = true;
      const itemsFiltrados = this.compCancelItems.filter(
        (x) => x.mProvIdDeu == this.compId
      );
      this.comprobantesItems = itemsFiltrados;
      this.loadingTable = false;
    },
    goToVerComprobante(item) {
      if (item.esOp) {
        this.$router.push({
          name: "VerOrdenDePago",
          params: {
            movProvId: item.mProvId,
            isCtaCte: true,
            monedaToDetalle: this.monedaObject.monedaToDetalle,
            fechaCotizaToDetalle: this.monedaObject.fechaCotizaToDetalle,
            goBackName: "CuentaCorrienteProveedores",
          },
        });
      } else {
        this.$router.push({
          name: "DetalleComprobanteProv",
          params: {
            movProvId: item.mProvId,
            monedaToDetalle: this.monedaObject.monedaToDetalle,
            fechaCotizaToDetalle: this.monedaObject.fechaCotizaToDetalle,
            goBackName: "CuentaCorrienteProveedores",
          },
        });
      }
    },
    goToDeleteComprobante(item) {
      this.openModalDelete = true;
      this.paramsToDelete = {
        movProvId: item.mProvId,
        isCtaCte: true,
        monedaToDetalle: this.monedaObject.monedaToDetalle,
        fechaCotizaToDetalle: this.monedaObject.fechaCotizaToDetalle,
      };
    },
    closeModal() {
      this.$emit("closeCompCancelatorios");
    },
  },
};
</script>

<style scoped></style>
